export const portfolioObject = [
  {
    id: 1,
    titleCard: "portfolio.titleCard1",
    technology: "portfolio.technology1",
    description: "portfolio.description1",
    img: "assets/Portfolio/myWebsite.png",
    link: "",
  },
  {
    id: 2,
    titleCard: "portfolio.titleCard2",
    technology: "portfolio.technology2",
    description: "portfolio.description2",
    img: "assets/Portfolio/moviesApi.png",
    link: "https://nayeliandrade.github.io/proyectosPersonales/peliculas/",
  },
  {
    id: 3,
    titleCard: "portfolio.titleCard3",
    technology: "portfolio.technology3",
    description: "portfolio.description3",
    img: "assets/Portfolio/pikachu.png",
    link: "https://nayeliandrade.github.io/proyectosPersonales/pikachu/",
  },
  {
    id: 4,
    titleCard: "portfolio.titleCard4",
    technology: "portfolio.technology4",
    description: "portfolio.description4",
    img: "assets/Portfolio/apiPixabay.png",
    link: "https://nayeliandrade.github.io/proyectosPersonales/pixabayApi/",
  },
  {
    id: 5,
    titleCard: "portfolio.titleCard5",
    technology: "portfolio.technology5",
    description: "portfolio.description5",
    img: "assets/Portfolio/userRegistration.png",
    link: "https://nayeliandrade.github.io/proyectosPersonales/clase8/",
  },
  {
    id: 6,
    titleCard: "portfolio.titleCard6",
    technology: "portfolio.technology6",
    description: "portfolio.description6",
    img: "assets/Portfolio/amortizationTable.png",
    link: "https://nayeliandrade.github.io/proyectosPersonales/tablaAmortizacion/",
  },
  {
    id: 7,
    titleCard: "portfolio.titleCard7",
    technology: "portfolio.technology7",
    description: "portfolio.description7",
    img: "assets/Portfolio/cardsTiktokers.png",
    link: "https://nayeliandrade.github.io/proyectosPersonales/cartasTiktokers/",
  },
  {
    id: 8,
    titleCard: "portfolio.titleCard8",
    technology: "portfolio.technology8",
    description: "portfolio.description8",
    img: "assets/Portfolio/toDoList.png",
    link: "https://nayeliandrade.github.io/proyectosPersonales/todolist/",
  },
];
