import styled from "@emotion/styled";
import { colors, fontWeight, fonts, fontSize } from "../../variables.styled";

export const AboutmeContainerStyled = styled.div`
  background-image: url(assets/About/about.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  object-fit: contain;
  backgrond-position: center center;
  margin: 5rem 1rem;
  max-width: 1210px;
  border: 2px solid black;
  @media (min-width: 1054px) {
    margin: 5rem auto;
  }
`;
export const ContainerTitleWidth = styled.div`
  width: 100%;
  @media (min-width: 800px) {
    max-width: 706px;
  }
  @media (min-width: 1180px) {
    width: 350px;
  }
`;
export const BgcBlackStyled = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 1280px;
  padding: 8rem 1rem;
  background-color: ${colors.fourth_black};
  margin: 1rem;
  @media (min-width: 1180px) {
    flex-direction: row-reverse;
  }
`;

export const ContainerTextStyled = styled.div`
  color: ${colors.primary_white};
  order: 2;
  padding-top: 2rem;
  p {
    font-weight: ${fontWeight.bold};
    font-family: ${fonts.secondary};
    font-size: ${fontSize.fontSizeP};
    padding-bottom: 1rem;
  }
  @media (min-width: 744px) {
    width: 662px;
    padding-right: 2rem;
    p {
      font-size: ${fontSize.fontSizePMq};
    }
  }
`;

export const ContainerTitleStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  order: 1;
`;

export const BarPinkStyled = styled.div`
  background-color: ${colors.secondary_Pink};
  width: 5rem;
  height: 0.5rem;
`;
export const ContainerTitleLineStyled = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
`;
export const TitlePinkStyled = styled.span`
  color: ${colors.secondary_Pink};
  font-weight: ${fontWeight.bold};
  font-family: ${fonts.secondary};
  font-size: ${fontSize.fontSizeTitlePink};
  @media (min-width: 744px) {
    font-size: ${fontSize.fontSizeTitlePinkMq};
  }
`;
export const SubTitleStyled = styled.h3`
  color: ${colors.primary_white};
  font-family: ${fonts.secondary};
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.fontSizeSubtitle};
  @media (min-width: 744px) {
    font-size: ${fontSize.fontSizeSubtitleMq};
  }
`;
