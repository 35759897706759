import styled from "@emotion/styled";
import { colors, fontWeight, fonts, fontSize } from "../../variables.styled";

export const ContainerTitleStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  order: 1;
  width: 100%;
`;

export const BarPinkStyled = styled.div`
  background-color: ${colors.secondary_Pink};
  height: 0.5rem;
  width: 100%;
  margin: 0 1rem;
`;
export const ContainerTitleLineStyled = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
  @media (min-width: 1180px) {
    .barDisplayNone {
      display: none;
    }
  }
`;
export const TitlePinkStyled = styled.span`
  color: ${colors.secondary_Pink};
  font-weight: ${fontWeight.bold};
  font-family: ${fonts.secondary};
  font-size: ${fontSize.fontSizeTitlePink};
  @media (min-width: 744px) {
    font-size: ${fontSize.fontSizeTitlePinkMq};
  }
`;
export const SubTitleStyled = styled.h3`
  padding-top: 1rem;
  color: ${colors.primary_white};
  font-family: ${fonts.secondary};
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.fontSizeSubtitle};
  @media (min-width: 744px) {
    font-size: ${fontSize.fontSizeSubtitleMq};
  }
`;
