import React from "react";
import {
  SkillsCardStyled,
  ImgSkillsStyled,
  TitleSkillsStyled,
  ParagraphSkillsStyled,
} from "./SkillsCard.styled";
import PropTypes from "prop-types";

const SkillsCard = ({ title, icon, description }) => {
  return (
    <SkillsCardStyled>
      <ImgSkillsStyled src={icon} alt={title} />
      <TitleSkillsStyled>{title}</TitleSkillsStyled>
      <ParagraphSkillsStyled>{description}</ParagraphSkillsStyled>
    </SkillsCardStyled>
  );
};

SkillsCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
export default SkillsCard;
