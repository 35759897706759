import React, { useState } from "react";
import Title from "../Title/Title";
import {
  ContainerTitleStyled,
  CardPorfolioStyled,
  ContainerImgStyled,
  ContainerTextStyled,
  TitlePortfolioStyled,
  TecnologiesStyled,
  DescriptionStyled,
  ContainerPrevNextStyled,
} from "./Portfolio.styled";
import { useTranslation } from "react-i18next";
import { portfolioObject } from "./portfolioObject.js";

const Portfolio = () => {
  const [t] = useTranslation("global");
  const [currentItem, setCurrentItem] = useState(portfolioObject[0].id);

  const linkPortfolio = () => {
    const portfolioItem = portfolioObject[currentItem];
    window.open(portfolioItem.link, "_blank");
  };
  const previous = () => {
    setCurrentItem((prevItem) => {
      if (prevItem === 0) {
        return portfolioObject.length - 1;
      } else {
        return prevItem - 1;
      }
    });
  };
  const next = () => {
    setCurrentItem((prevItem) => {
      if (prevItem === portfolioObject.length - 1) {
        return 0;
      } else {
        return prevItem + 1;
      }
    });
  };
  return (
    <>
      <ContainerTitleStyled id="portfolio">
        <Title
          title={t("portfolio.title")}
          subtitle={t("portfolio.subtitle")}
        />
      </ContainerTitleStyled>
      <CardPorfolioStyled onClick={linkPortfolio}>
        <ContainerImgStyled>
          <img
            src={portfolioObject[currentItem].img}
            alt={t(portfolioObject[currentItem].titleCard)}
          />
        </ContainerImgStyled>
        <ContainerTextStyled>
          <TitlePortfolioStyled>
            {t(portfolioObject[currentItem].titleCard)}
          </TitlePortfolioStyled>
          <TecnologiesStyled>
            {t(portfolioObject[currentItem].technology)}
          </TecnologiesStyled>
          <DescriptionStyled>
            {t(portfolioObject[currentItem].description)}
          </DescriptionStyled>
        </ContainerTextStyled>
      </CardPorfolioStyled>
      <ContainerPrevNextStyled>
        <img src="assets/Portfolio/arrowBack.svg" onClick={previous} />
        <img src="assets/Portfolio/arrowForward.svg" onClick={next} />
      </ContainerPrevNextStyled>
    </>
  );
};

export default Portfolio;
