import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import Title from "../Title/Title";
import {
  ContainerImgLeaves,
  ContainerImgBlue,
  ContainerBlack,
  ContainerMessage,
  ContainerInputs,
} from "./Contact.styled";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const [t] = useTranslation("global");
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_thh0lrw",
        "template_8q2nm0h",
        form.current,
        "kkZHwfoXGhNuJIItW"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <ContainerImgLeaves id="contact">
      <ContainerImgBlue>
        <ContainerBlack>
          <ContainerMessage>
            <Title
              title={t("contact.title")}
              subtitle={t("contact.subtitle")}
            />
            <p>{t("contact.message")}</p>
          </ContainerMessage>
          <ContainerInputs>
            <form ref={form} onSubmit={sendEmail}>
              <input
                type="text"
                name="user_name"
                placeholder={t("contact.inputName")}
                required
              />
              <input
                type="email"
                name="user_email"
                placeholder={t("contact.inputEmail")}
                required
              />
              <textarea
                name="message"
                placeholder={t("contact.inputMessage")}
                required
              />
              <button type="submit" value={t("contact.buttonSend")}>
                {t("contact.buttonSend")}
              </button>
            </form>
          </ContainerInputs>
        </ContainerBlack>
      </ContainerImgBlue>
    </ContainerImgLeaves>
  );
};

export default Contact;
