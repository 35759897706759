export const experienceObject = [
  {
    id: 1,
    rol: "experience.rol1",
    company: "experience.company1",
    location: "experience.location1",
    startDate: "experience.startDate1",
    endDate: "experience.endDate1",
    activity1: "experience.activity1.1",
    activity2: "experience.activity2.1",
    activity3: "experience.activity3.1",
    activity4: "experience.activity4.1",
    activity5: "experience.activity5.1",
  },
  {
    id: 2,
    rol: "experience.rol2",
    company: "experience.company2",
    location: "experience.location2",
    startDate: "experience.startDate2",
    endDate: "experience.endDate2",
    activity1: "experience.activity1.2",
    activity2: "experience.activity2.2",
    activity3: "experience.activity3.2",
    activity4: "experience.activity4.2",
    activity5: "experience.activity5.2",
  },
];
