import React from "react";
import {
  AboutmeContainerStyled,
  BgcBlackStyled,
  ContainerTextStyled,
  ContainerTitleWidth,
} from "./AboutMe.styled";
import PropTypes from "prop-types";
import Title from "../Title/Title";
import { useTranslation } from "react-i18next";

const AboutMe = ({ p1, p2 }) => {
  const [t] = useTranslation("global");

  return (
    <AboutmeContainerStyled id="aboutMe">
      <BgcBlackStyled>
        <ContainerTextStyled>
          <p>{p1}</p>
          <p>{p2}</p>
        </ContainerTextStyled>
        <ContainerTitleWidth>
          <Title title={t("about.title")} subtitle={t("about.subtitle")} />
        </ContainerTitleWidth>
      </BgcBlackStyled>
    </AboutmeContainerStyled>
  );
};

AboutMe.propTypes = {
  p1: PropTypes.string.isRequired,
  p2: PropTypes.number.isRequired,
};

export default AboutMe;
