import React from "react";
import {
  ContainerTitleStyled,
  ContainerTitleLineStyled,
  BarPinkStyled,
  TitlePinkStyled,
  SubTitleStyled,
} from "./Title.styled";
import PropTypes from "prop-types";

const Title = ({ title, subtitle }) => {
  return (
    <ContainerTitleStyled>
      <ContainerTitleLineStyled>
        <BarPinkStyled />
        <TitlePinkStyled>{title}</TitlePinkStyled>
        <BarPinkStyled className="barDisplayNone" />
      </ContainerTitleLineStyled>
      <SubTitleStyled>{subtitle}</SubTitleStyled>
    </ContainerTitleStyled>
  );
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default Title;
