import styled from "@emotion/styled";
import { colors, fontWeight, fonts, fontSize } from "../../variables.styled";

// GRADIENT CONTAINER
export const TopHeaderStyled = styled.div`
  background: linear-gradient(
    90deg,
    #d206e4 0%,
    #7c19ed 41.88%,
    #7c19ed 57.78%,
    #d206e4 101.78%
  );
  height: 45px;
`;

// NAV BAR
export const NavBarStyled = styled.nav`
  z-index: 1;
  padding: 0.5rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: black;
  position: sticky;
  top: 0;
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.fontSizeP};
  font-family: ${fonts.primary};
  color: ${colors.primary_white};
  h2 {
    margin: 0.5rem 0;
    font-weight: ${fontWeight.bold};
  }

  img {
    width: 50px;
    margin-left: 2rem;
    border-radius: 5px;
  }

  .active {
    display: flex;
  }

  @media (min-width: 1080px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;
export const UlContainerStyled = styled.ul`
  display: none;
  flex-direction: column;
  align-items: stretch;
  padding: 0 0 1rem 1rem;
  img {
    display: none;
  }
  @media (min-width: 1080px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 2rem 0 0;
    img {
      display: block;
    }
  }
`;
export const LiStyled = styled.li`
  margin: 1rem;
  list-style-type: none;
  .linkA {
    color: ${colors.primary_white};
    text-decoration: none;
    margin-right: 1.5rem;

    :hover {
      color: ${colors.secondary_Pink};
    }
  }
`;

export const ContainerLogoStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  h2 {
    padding: 0 2rem;
  }
  img {
    width: 35px;
    cursor: pointer;
  }
  @media (min-width: 1080px) {
    width: 60px;
    h2 {
      padding: 0 2rem;
    }
    img {
      display: none;
    }
  }
`;

export const ContainerLogoMenu = styled.div`
  display: flex;
`;
export const ContainerFlag = styled.div`
  img {
    width: 50px;
  }
`;
