import styled from "@emotion/styled";
import { colors, fontWeight, fonts } from "../../variables.styled";
export const ContainerImgLeaves = styled.div`
  border: 2px solid black;
  background-image: url(assets/Contact/leavesLeft.png),
    url(assets/Contact/leavesRight.png);
  background-repeat: no-repeat;
  background-size: 20%;
  background-position: bottom left, bottom right;
`;
export const ContainerImgBlue = styled.div`
  background-image: url(assets/Contact/bgContact.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  object-fit: contain;
  backgrond-position: center center;
  margin: 5rem 1rem;
  max-width: 1210px;
  border: 2px solid black;
  @media (min-width: 1054px) {
    margin: 5rem auto;
  }
`;
export const ContainerBlack = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 1280px;
  padding: 8rem 1rem;
  background-color: ${colors.fourth_black};
  margin: 1rem;
  @media (min-width: 700px) {
    flex-direction: row;
  }
`;
export const ContainerMessage = styled.div`
  max-width: 400px;
  p {
    margin-top: 2rem;
  }
  color: ${colors.primary_white};
  margin: 1rem;
  font-size: 20px;
  font-weight: ${fontWeight.middle};
  font-family: ${fonts.secondary};
`;
export const ContainerInputs = styled.div`
  width: 290px;
  padding: 1rem;
  input,
  textarea {
    border-radius: 0.5rem;
    padding: 1rem;
    margin: 0.5rem;
    width: 100%;
    border: 2px solid ${colors.secondary_Pink};
    background-color: ${colors.fourth_black};
    color: ${colors.primary_white};
  }
  button {
    cursor: pointer;
    border-radius: 0.5rem;
    padding: 1rem;
    margin: 0.5rem;
    width: 100px;
    color: ${colors.primary_white};
    background-color: ${colors.secondary_Pink};
    border: none;
  }

  @media (min-width: 700px) {
    width: 50%;
    input,
    textarea {
      width: 100%;
    }
  }
`;
